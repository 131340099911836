import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import cool from '../../img/cool.png';
import n from '../../img/n.png';
import groupMuscu from '../../img/groupmuscu.jpg';
import groupMuscu2 from '../../img/groupmuscu2.jpg';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

export const SwiperForWho = () => {
  return (
    <div>
      <Swiper
        style={{
          '--swiper-pagination-color': '#F54C5A',
          '--swiper-pagination-color-active': '#75A1FF',
          '--swiper-pagination-bullet-size': '15px',
          '--swiper-pagination-bullet-width': '15px',
          '--swiper-pagination-bullet-inactive-color':
            'rgba(255, 255, 255, 0.2)',
          '--swiper-pagination-bullet-inactive-opacity': '1',
          '--swiper-pagination-bullet-inactive-width': '50px',
        }}
        spaceBetween={30}
        pagination={{ clickable: true }}
        className="swiper-who max-w-[480px] lg:max-w-[700px]"
        autoplay={{
          delay: 4500,
        }}
        loop={true}
        modules={[Pagination, Autoplay, Navigation]}
      >
        <SwiperSlide className=" flex items-center">
          <img className="rounded-3xl" src={cool} alt="" />
        </SwiperSlide>

        <SwiperSlide className=" flex items-center">
          <img className="rounded-md" src={groupMuscu} alt="" />
        </SwiperSlide>

        <SwiperSlide className=" flex items-center">
          <img className="rounded-md" src={groupMuscu2} alt="" />
        </SwiperSlide>

        <SwiperSlide className=" flex items-center">
          <img className="rounded-3xl" src={n} alt="" />
        </SwiperSlide>
      </Swiper>
    </div>
  );
};
