import logoBlue from '../img/SUMMIT.svg';
import logoWhite from '../img/SUMMIT-WHITE.svg';
import React, { useEffect } from 'react';
import { ButtonCalendly } from './button-calendly';
import { Link } from 'react-router-dom';

export const FixedBand = ({ withCalendly = false }) => {
  const [isScrollZero, setIsScrollZero] = React.useState(true);
  const bg = isScrollZero ? 'bg-[#75A1FF]' : 'bg-[#131369]';
  const logo = isScrollZero ? logoBlue : logoWhite;
  const shadow = !isScrollZero ? 'shadow-fix' : '';
  const urgence = isScrollZero ? 'bg-[#131369]' : 'bg-[#F54C5A]';

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 400) {
        setIsScrollZero(false);
      } else {
        setIsScrollZero(true);
      }
    };

    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  return (
    <div className={`fixed header-fixed w-full ${bg} ${shadow} top-0 z-50`}>
      <div
        className={`transition-all flex justify-center items-center h-[100px] w-12/12  lg:mx-auto`}
      >
        <div className="w-6/12 flex justify-center">
          <Link to="/">
            <img
              className="w-full h-[auto] max-w-[230px] lg:h-[auto]"
              src={logo}
              fill="white"
              alt="logo"
            />
          </Link>
        </div>
        {!withCalendly && (
          <div className="flex justify-center h-[100%] w-6/12 flex-col items-center lg:justify-center lg:flex-row">
            <span className="text-white flex-col flex  text-right text-sm font-semibold w-fit  lg:flex-row lg:item-center lg:justify-center lg:w-fit lg:text-2xl">
              <span className={`p-2 ${urgence} rounded-lg text-center`}>
                8 places
              </span>
              <span className="block m-auto lg:ml-1">disponibles</span>
            </span>
          </div>
        )}
        {withCalendly && <ButtonCalendly />}
      </div>
    </div>
  );
};
