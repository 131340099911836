import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import star from '../../img/Star 4.png';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

export const SwiperReview = () => {
  const isMobile = window.innerWidth < 768;
  const inactiveColor = isMobile ? '#ffffff' : 'rgba(0, 0, 0, 0.2)';
  return (
    <div>
      <Swiper
        style={{
          '--swiper-pagination-color': '#F54C5A',
          '--swiper-pagination-color-active': '#75A1FF',
          '--swiper-pagination-bullet-size': '15px',
          '--swiper-pagination-bullet-width': '15px',
          '--swiper-pagination-bullet-inactive-color': 'rgba(0, 0, 0, 0.2)',
          '--swiper-pagination-bullet-inactive-opacity': '1',
          '--swiper-pagination-bullet-inactive-width': '50px',
        }}
        pagination={{ clickable: true }}
        className="swiper-reviews h-[500px] lg:h-[600px] w-full"
        loop={true}
        modules={[Pagination, Navigation]}
      >
        {[...testimonialsSeb, ...testimonialsYounes, ...testimonialsArray].map(
          (testimonial, index) => {
            return (
              <SwiperSlide className=" flex items-center">
                <div className="bg-[#F4F7FB] p-5 h-full">
                  <h3 className="font-semibold text-lg text-black">
                    {testimonial.name}
                  </h3>
                  <p className="text-black text-base">
                    Avis certifié sur {testimonial.from}
                  </p>
                  <div className="flex mt-5">
                    {[...Array(5)].map((_, i) => (
                      <img key={i} src={star} alt="star" />
                    ))}
                  </div>
                  <div className="text-base lg:text-md border-2 p-2 border-gray-400 rounded-xl lg:border-0 leading-5 text-black my-5 overflow-hidden overflow-y-scroll lg:overflow-y-auto max-h-[300px]">
                    {testimonial.text}
                  </div>
                </div>
              </SwiperSlide>
            );
          }
        )}
      </Swiper>
    </div>
  );
};

const testimonialsSeb = [
  {
    name: 'Jacques-yves',
    from: 'Linkedin',
    text: "Sébastien a été d'une aide précieuse pour structurer tout le Système d'Information d'Openeat, il a mis en place une architecture exigeante, scalable et des bonnes pratiques qui ont mis l'entreprises sur des fondations solides pour envisager une croissance sereine : sécurité, RGPD, norme ISO, scalabilité, R&D, etc.. Il a été un moteur particulièrement dynamique pour l'équipe qu'il a recruté et nous a accompagné sur des problématiques plus généralistes comme la conception du produit ou encore la montée en méthodologie du travail des P.O",
  },
  {
    name: 'Dominique',
    from: 'Linkedin',

    text: "Grâce à Sebastien, qui est intervenu comme prestataire en développement puis comme CTO, MeltinPot.org a pu mettre en place un site riche et performant avec un budget contenu : une interactivité poussée, une UX fluide et bien construite grâce à ses expériences passées au lancement de multiples projets complexes, aussi bien pour des grandes entreprises que pour des startups. Au global Seb a réalisé un travail technique très solide sur MeltinPot en proposant des solutions efficientes et astucieuses qui nous ont permis de réaliser toutes nos fonctionnalités et même au delà .En tant qu'associé il nous a apporté de nombreuses idées et solutions aux problèmes et questions posées, tout en gardant une rafraichissante capacité à penser out of the box.",
  },
];

export const testimonialsYounes = [
  {
    name: 'Messaouda',
    from: 'Linkedin',
    text: `
    Younes, un grand Merci ! 

Je suis ravie de recommander Younes pour son incroyable soutien dans ma transition vers le monde du freelancing. Grâce à ses conseils précieux en matière commerciale, marketing, juridique et comptable, j'ai pu naviguer avec succès dans les aspects les plus complexes de cette nouvelle aventure. Sa créativité et ses astuces innovantes m'ont permis d'optimiser mes processus et de maximiser mes revenus, me conduisant à générer un chiffre d'affaires à plusieurs milliers d'euros. Younes est un véritable atout pour quiconque cherche à se lancer en freelance. J’avais peur de prendre le risque de troquer mon CDI pour un contrat de freelance mais Younes m’a rassuré et m’a encouragé à poursuivre dan cette voie.
Il a été un véritable support pour mes négociations de TJM et mon changement de statuts juridiques. 
Jongler avec tous ces aspects est une véritable gymnastique. 
Encore Merci Younes !`,
  },
  {
    name: 'Cédric',
    from: 'Linkedin',

    text:
      "J'ai grandement apprécié échanger avec Younes. Dès le début," +
      " il a su poser les questions pertinentes et identifier les points de blocages. En plus de m'offrir des conseils extrêmement utiles pour améliorer mes compétences en vente, notamment pour les offres de mon SaaS, il m'a également aidé à élaborer de nouvelles stratégies marketing.",
  },
  {
    name: 'Shaaban',
    from: 'Linkedin',

    text: "Younes est une personne d'une honnêteté remarquable. Dès notre première rencontre, j'ai été frappé par son intégrité et sa sincérité. Au fil de nos discussions approfondies sur mes projets, il a su me prodiguer des conseils d'une grande efficacité. Avec Younes à mes côtés, même au bout du monde, je me sens pleinement soutenu et inspiré.",
  },
  {
    name: 'Clémentine',
    from: 'Linkedin',

    text: 'Un vrai plaisir de travailler avec Younes ! Réactif et très disponible, Younes a su montrer de vraies qualités pour améliorer notre business commun !',
  },
];

export const testimonialsArray = [
  {
    name: 'Luc',
    from: 'Google',

    text:
      'Une expérience sans équivalent avec une équipe extrêmement scrupuleuse et attentionnée. Ouvert à tous, la dynamique offre à chacun la possibilité de donner son maximum quel que soit son niveau de départ et ses objectifs dans une atmosphère plus que sympathique.\n' +
      'On revient reposé, plein d’énergie avec un peu de nostalgie et l’envie d’en faire profiter tous ses proches.\n' +
      'Un grand merci à l’équipe d’encadrement au complet et aux coachs tous différents, complémentaires, extraordinaires !',
  },
  {
    name: 'Lilian',
    from: 'Google',

    text: 'Je viens de passer 2 semaines dans ce village unique. J’y ai trouvé que de la gentillesse , bonne humeur et encouragements, c’est formidable. L’endroit est sympa, et on y mange bien (Merci Rachid!) Un grand merci à l’equipe des coaches: Amélie, Nico et Pablo. Et n’oublions pas les attentions et le sourire de Nadja ! A très vite !!',
  },
  {
    name: 'Nicolas',
    from: 'Google',

    text: "Un endroit magnifique, un encadrement au top, de la bienveillance pour tous les participants quelque soit la raison de leur venue, et beaucoup de gens qui reviennent, ca trompe pas et ca montre la qualité de coaching village. On s'entraîne dans la bonne humeur avec des coachs au top et tous ceux qui y travaillent. Je pensais pas vivre une semaine pareille avec de belles rencontres et de beaux souvenirs. Merci à toute la bande vous êtes géniaux, à la prochaine ;)",
  },
];
