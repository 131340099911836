export const collapse = [
  {
    question:
      'Quels résultats puis-je attendre au bout des 10 jours pour ma santé physique ?',
    answer: [
      `
    Au terme de ces 10 jours, vous pourrez constater une amélioration significative de votre condition physique, accompagnée d'un rééquilibrage alimentaire pour favoriser votre bien-être général. Vous ressentirez également un niveau d'énergie accru pour affronter les défis du quotidien. 

Vous aurez surement perdu quelques kilos aussi grâces à nos repos équilibrés !

Pour être performant intellectuellement il faut aussi être en forme physiquement !

`,
    ],
  },
  {
    question:
      'Quels résultats puis-je attendre au bout des 10 jours au niveau de mes compétences?',

    answer: [
      "Vous aurez eu l'opportunité d'acquérir et de mettre en pratique des connaissances essentielles en vente, en personal branding et en création d'entreprise, que ce soit pour développer vos activités en freelance ou pour lancer une startup. Ces résultats combinés visent à vous offrir une expérience enrichissante et transformative.",
      'Que des infos pratiques et utiles, pas de blabla !',
    ],
  },
  {
    question: 'Quels résultats puis-je attendre du mentorat sur 90 jours ?',
    answer: [
      'Si vous suivez les conseils acquis et appliquez les enseignements reçus, vous pouvez vous attendre, dès les premières semaines, à attirer des prospects et des leads. Vous saurez aussi dans quelle direction aller, vous n’êtes plus seuls ! Nous sommes à vos côtés pour vous épauler !',
      "Par ailleurs, si vous choisissez de passer freelance en appliquant nos conseils et stratégies, selon votre niveau d'expérience et votre positionnement vous pourrez postuler à des postes à partir de 500 euros par jour (moyenne constatée chez nos collaborateurs). ",
      'Enfin, vous développerez la capacité de créer rapidement des applications, des logiciels en tant que service (SaaS) ou des entreprises pour tester vos idées, sans perdre de temps à réaliser des expérimentation infructueuses.',
      'Notre accompagnement continu vous aidera à prendre les bonnes décisions pour garantir le succès de vos projets à long terme.',
    ],
  },
  {
    question: 'Qui seront les intervenants au cours des 90 jours ?',
    answer: [
      'Nos intervenants seront diversifiés et spécialisés dans différents domaines qui nous semblent essentiels pour réussir sur le marché.',
      "Vous bénéficierez de l'expertise de professionnels en prospection, en vente et en closing, ainsi que de témoignages inspirants d'entrepreneurs à succès et de freelances prospères.",
      "De plus, notre équipe comprend des agents de freelance, des spécialistes en développement d'applications web et en logiciels en tant que service (SaaS). Chacun de ces intervenants apportera une valeur ajoutée unique à votre parcours, vous permettant ainsi d'acquérir des compétences essentielles pour réussir dans votre entreprise ou en tant que freelance.",
    ],
  },
  {
    question: "Est-ce qu'il faut être développeur web ?",
    answer: [
      "Non, il n'est pas nécessaire d'être développeur web pour participer à notre programme.",
      "Si vous possédez de l'expérience dans des domaines transversaux liés aux produits web, vous êtes également bienvenu",
      'Toutefois, il est important de noter que la majorité des activités commerciales sont désormais liées aux applications web ou mobiles.',
      "Par conséquent, vous devrez être capable d'utiliser des outils de 'no code' ou de 'low code', ou bien d'établir des synergies et des partenariats avec des développeurs.",
      "Nous vous accompagnerons pour acquérir les compétences nécessaires et maximiser votre potentiel de réussite, quel que soit votre domaine d'expertise initial.",
    ],
  },
  {
    question: 'Pourquoi on part au Maroc ?',
    answer: [
      "Nous choisissons le Maroc comme lieu de notre bootcamp car nous croyons fermement que pour réussir sa transformation, il est essentiel de changer d'environnement et d'être entouré de personnes inspirantes.",
      "Vous avez sûrement entendu dire que vous êtes le résultat des cinq personnes qui vous entourent. Maintenant, imaginez l'impact de côtoyer dix individus motivés à réussir. ",
      "Le Maroc offre un cadre propice à l'épanouissement personnel et professionnel, favorisant ainsi une transformation profonde et durable.",
      'Et le plaisir d’être au soleil !',
    ],
  },
  {
    question: 'Pourquoi on doit faire du sport ?',
    answer: [
      'Faire du sport est essentiel pour maintenir un mode de vie sain et équilibré.',
      'Non seulement il contribue à améliorer notre condition physique et à renforcer notre système immunitaire, mais il joue également un rôle crucial dans notre bien-être mental et émotionnel.',
      "L'exercice régulier aide à réduire le stress, à améliorer la concentration et à favoriser un sommeil de meilleure qualité. De plus, il peut également stimuler la confiance en soi et favoriser des interactions sociales positives. En participant à des activités physiques adaptées à vos besoins et à votre niveau, vous investissez dans votre santé globale et vous vous donnez les moyens d'atteindre vos objectifs personnels et professionnels avec énergie et détermination.",
    ],
  },
];
