import { Video } from './video';
import { ButtonCalendly } from './button-calendly';
import { InfosBootcamp } from './infos-bootcamp';

export const HeaderBase = () => {
  return (
    <header className="mt-[100px] lg:mt-[50px]">
      <div className="lg:flex w-full lg:mx-auto">
        <div className="flex justify-center flex-col  mx-auto items-center lg:w-6/12 mt-0 relative">
          <span className="text-md mt-14 mb-0  font-extrabold text-[#F54C5A]  z-0  px-9 py-2 rounded-full border-2 bg-transparent border-[#F54C5A]">
            100 jours
          </span>
          <p className="text-center font-medium mt-5 text-3xl z-10 lg:text-5xl">
            POUR DEVENIR FREELANCE & ENTREPRENEUR LIBRE
          </p>
          <p className="mt-3 w-full max-w-[370px] mx-auto text-center lg:text-2xl lg:mt-10 lg:max-w-[500px]">
            Acquérir les compétences pour vendre et lancer des entreprises.
          </p>
          <div className="mt-14">
            <ButtonCalendly withDiscord={{ withDiscord: true }} />
          </div>
        </div>

        <div className="lg:mt-20 lg:w-6/12 flex flex-col items-center">
          <Video />
          <InfosBootcamp />
        </div>
      </div>
    </header>
  );
};
