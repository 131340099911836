export const Essaouira = () => {
  return (
    <div className="lg:flex lg:h-[500px]">
      <div className="lg:flex lg:justify-center lg:items-center bg-gray-300 lg:w-4/12">
        <div className="bg-[#F54C5A] min-h-96 lg:h-[300px] flex flex-col justify-center items-center">
          <p className="text-2xl font-normal mb-2">INFO PLUS</p>
          <p className="text-5xl font-extrabold lg:px-20 text-center">
            Essaouira, la Saint Malo marocaine.
          </p>
        </div>
      </div>
      <div className="flex justify-center items-center lg:mt-14 lg:w-8/12">
        <p className="text-sm px-6 py-12 mx-auto font-semibold lg:text-2xl lg:pr-24">
          Essaouira est une ville portuaire et une station balnéaire du Maroc,
          située sur la côte atlantique. Elle est connue pour ses plages, ses
          sports nautiques et ses sites historiques. La médina d'Essaouira est
          un site classé au patrimoine mondial de l'UNESCO. Elle est entourée de
          remparts du XVIIIe siècle et abrite des ruelles étroites, des souks et
          des maisons blanches aux volets bleus. La ville est également célèbre
          pour son festival de musique Gnaoua, qui se tient chaque année en
          juin.
        </p>
      </div>
    </div>
  );
};
