import calendar from '../img/calendar.svg';
import position from '../img/position.svg';

export const InfosBootcamp = () => {
  return (
    <div className="flex justify-center items-center bg-[#6d6e90] rounded-xl mt-14 mx-auto w-11/12 max-w-[350px] h-[58px]">
      <div className="flex items-center">
        <img className="w-8 mr-3" src={calendar} />
        <span className="text-white font-extrabold">31 mai - 9 juin</span>
      </div>

      <div className="ml-2 flex items-center">
        <img src={position} className="w-8" />
        <span className="text-white font-extrabold">Maroc</span>
      </div>
    </div>
  );
};
