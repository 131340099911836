import { Footer } from '../components/Footer';
import { FixedBand } from '../components/fixed-band';

export const Confidentialite = () => {
  window.scrollTo(0, 0);

  return (
    <div className="block">
      <div className="h-auto flex justify-center items-center">
        <FixedBand withCalendly={true} />
      </div>

      <div class="container mt-36 mx-auto px-4 py-8">
        <h1 class="text-3xl font-bold mb-4">
          PROTECTION DE VOS DONNÉES PERSONNELLES
        </h1>

        <p class="mb-4">
          Dans le cadre de l’utilisation des services accessibles sur nos sites
          internet, (y compris sites mobiles) Summit Maroc, en qualité de
          responsable de traitement, est susceptible de collecter et traiter des
          données à caractère personnel vous concernant.
        </p>

        <p class="mb-4">
          Summit Maroc est attachée au respect des règles de protection de la
          vie privée de ses clients, usagés, des prospects et des visiteurs de
          ses site internet. L'ensemble des traitements de données personnelles
          mis en œuvre dans le cadre des services accessibles respecte la
          réglementation locale applicable en matière de protection des données
          personnelles et notamment les dispositions de la loi « informatique et
          libertés » du 6 janvier 1978 modifiée et le Règlement Général sur la
          Protection des Données (Règlement UE 2016/679) ou « RGPD ».
        </p>

        <p class="mb-4">
          Summit Maroc est responsable du traitement de vos données personnelles
          dans le cadre de ces programmes.
        </p>

        <h2 class="text-xl font-bold mb-2">
          QUELS SONT LES ENGAGEMENTS DE Summit Maroc EN MATIÈRE DE PROTECTION
          DES DONNÉES PERSONNELLES ?
        </h2>

        <p class="mb-4">
          Summit Maroc s'engage à garantir un niveau de protection élevé des
          données personnelles de ses clients, prospects, utilisateurs de ses
          sites internet et de toute autre personne dont elle traite les données
          personnelles.
        </p>

        <p class="mb-4">
          Summit Maroc s'engage à respecter la règlementation applicable à
          l'ensemble des traitements de données personnelles qu'elle met en
          œuvre. Plus particulièrement, Summit Maroc s'engage notamment à
          respecter les principes suivants :
        </p>

        <ul class="list-disc pl-8 mb-4">
          <li>
            Vos données personnelles sont traitées de manière licite, loyale et
            transparente (licéité, loyauté, transparence).
          </li>
          <li>
            Vos données personnelles sont collectées pour des finalités
            déterminées, explicites et légitimes, et ne sont pas traitées
            ultérieurement d'une manière incompatible avec ces finalités
            (limitation des finalités).
          </li>
          <li>
            Vos données personnelles sont conservées de manière adéquate,
            pertinente et sont limitées à ce qui est nécessaire au regard des
            finalités pour lesquelles elles sont traitées (minimisation des
            données).
          </li>
          <li>
            Vos données personnelles sont exactes, tenues à jour et toutes les
            mesures raisonnables sont prises pour que les données inexactes, eu
            égard aux finalités pour lesquelles elles sont traitées, soient
            effacées ou rectifiées sans tarder (exactitude).
          </li>
        </ul>
      </div>

      <div class="container mx-auto px-4 py-8">
        <h2 class="text-xl font-bold mb-2">
          À QUOI SERVENT LES DONNÉES SUSCEPTIBLES D'ÊTRE COLLECTÉES PAR Summit
          Maroc ?
        </h2>

        <p class="mb-4">
          Summit Maroc utilise les données personnelles collectées dans le cadre
          de ses activités pour les finalités suivantes :
        </p>

        <ul class="list-disc pl-8 mb-4">
          <li>
            Assurer la fourniture des services proposés sur ses sites internet.
          </li>
          <li>
            Gérer les relations avec ses clients, prospects et utilisateurs.
          </li>
          <li>Améliorer la qualité des services et produits proposés.</li>
          <li>
            Réaliser des études statistiques et analyser les performances de ses
            sites internet.
          </li>
          <li>Respecter ses obligations légales et réglementaires.</li>
          <li>Prévenir la fraude et assurer la sécurité des données.</li>
        </ul>

        <p class="mb-4">
          Summit Maroc ne collecte que les données strictement nécessaires à la
          réalisation de ces finalités et s'engage à ne pas les utiliser à
          d'autres fins non autorisées.
        </p>
      </div>
      <div class="container mx-auto px-4 py-8">
        <h2 class="text-xl font-bold mb-2">
          QUI EST SUSCEPTIBLE D'ACCÉDER À VOS DONNÉES PERSONNELLES ?
        </h2>

        <h3 class="text-lg font-bold mb-2">1. Destinataires de vos données</h3>

        <p class="mb-4">
          Les données collectées sur les sites internet de Summit Maroc et ses
          sites mobile sont susceptibles d'être communiquées :
        </p>

        <ul class="list-disc pl-8 mb-4">
          <li>Au personnel habilité de Summit Maroc.</li>
          <li>
            À ses partenaires (agents accrédités, transporteurs, expéditeurs,
            sociétés de cartes bancaires, etc.) ou à ses prestataires de
            services annexes, dans le cadre de l'accomplissement de tout ou
            partie des prestations.
          </li>
          <li>
            Aux autorités publiques françaises ou étrangères habilitées,
            conformément aux lois et règlements applicables.
          </li>
        </ul>

        <p class="mb-4">
          Summit Maroc demande à ses prestataires de mettre en place des mesures
          strictes de confidentialité et de protection des données.
        </p>

        <h3 class="text-lg font-bold mb-2">
          2. Transferts de données hors de l’Union Européenne
        </h3>

        <p class="mb-4">
          Certains des destinataires mentionnés ci-dessus sont susceptibles
          d'être établis en dehors de l'Union européenne et d'avoir accès à tout
          ou partie des informations personnelles collectées par Summit Maroc.
        </p>

        <p class="mb-4">
          Summit Maroc s'engage à garantir la protection de vos données
          conformément aux règles les plus strictes. Cela inclut notamment la
          signature de clauses contractuelles basées sur le modèle de la
          commission européenne ou tout autre mécanisme conforme au RGPD,
          lorsque vos données personnelles sont traitées par un prestataire en
          dehors de l’Espace Economique Européen et dont le pays n’est pas
          considéré par la Commission Européenne comme assurant un niveau de
          protection adéquat.
        </p>
      </div>
      <div class="container mx-auto px-4 py-8">
        <h2 class="text-xl font-bold mb-2">COMMENT EXERCER VOS DROITS ?</h2>

        <p class="mb-4">
          Conformément à la réglementation applicable en matière de protection
          des données personnelles, vous pouvez, à tout moment, exercer vos
          droits :
        </p>

        <ul class="list-disc pl-8 mb-4">
          <li>D'accès,</li>
          <li>De rectification,</li>
          <li>De suppression des données vous concernant,</li>
          <li>De limitation et d’opposition au traitement,</li>
          <li>À la portabilité de vos données personnelles.</li>
        </ul>

        <p class="mb-4">
          Vous disposez légalement du droit de définir des directives relatives
          au sort de vos données à caractère personnel post mortem. De plus,
          toute personne mineure au moment de la collecte de ses données
          personnelles peut en obtenir l’effacement dans les meilleurs délais.
        </p>

        <p class="mb-4">
          Nous vous prions de bien vouloir accompagner votre demande des
          éléments nécessaires à votre identification (nom, prénom, e-mail)
          ainsi que toute autre information nécessaire à la confirmation de
          votre identité.
        </p>

        <p class="mb-4">
          Pour certains services spécifiques, ces droits sont susceptibles de
          s'exercer directement en ligne (gestion de votre compte utilisateur,
          gestion de vos réservations, programme de fidélité, etc.).
        </p>

        <p class="mb-4">
          Vous disposez également d'un droit de recours auprès de la Commission
          Nationale de l'Informatique et des Libertés en cas de violation de la
          réglementation applicable en matière de protection des Données
          Personnelles et notamment du RGPD.
        </p>

        <p>
          Summit Maroc collaborera lorsque cela s’avèrera nécessaire pour
          garantir l’exercice de vos droits, ou vous apporter les réponses à
          toutes vos questions ou réclamations.
        </p>
      </div>
      <div class="container mx-auto px-4 py-8">
        <h2 class="text-xl font-bold mb-2">COOKIES</h2>

        <p class="mb-4">
          Afin d'améliorer constamment la qualité des services proposés sur le
          site et leur adéquation avec vos attentes, Summit Maroc est
          susceptible d'utiliser des « cookies », fichiers texte servant à
          identifier votre terminal lorsque vous vous connectez à l'un de nos
          services.
        </p>

        <p class="mb-4">
          Le dépôt de cookie ou traceur dans votre terminal (ordinateur,
          tablette, smartphone, etc.) permet à Summit Maroc de collecter des
          informations et données personnelles. Selon votre choix de paramétrage
          de votre terminal, les cookies permettent notamment:
        </p>

        <ul class="list-disc pl-8 mb-4">
          <li>
            D'utiliser les principales fonctionnalités du site Summit Maroc.
          </li>
          <li>
            D’optimiser notre site internet et de détecter les éventuels
            problèmes techniques.
          </li>
          <li>
            De mémoriser vos choix et vos préférences et personnaliser votre
            interface utilisateur.
          </li>
        </ul>

        <p class="mb-4">
          En fonction des choix de paramétrage concernant l’utilisation des
          cookies, certains traceurs peuvent être émis ou lus par des tiers
          (prestataires de publicité, agences de communication, etc.) lors de
          votre navigation sur les sites de Summit Maroc.
        </p>

        <p class="mb-4">
          À tout instant, il vous est laissé la possibilité de régler vos
          paramétrages relatifs aux cookies. Pour plus d'informations sur
          l'utilisation de cookies par Summit Maroc, veuillez consulter notre
          politique de gestion de cookies.
        </p>
      </div>
      <div class="container mx-auto px-4 py-8">
        <h2 class="text-xl font-bold mb-2">
          SÉCURITÉ INFORMATIQUE / SÉCURISATION DES TRANSACTIONS
        </h2>

        <p class="mb-4">
          Veiller à la sécurité et à la confidentialité des données personnelles
          que vous nous confiez est une priorité pour Summit Maroc. Nous mettons
          ainsi en œuvre toutes les mesures techniques et organisationnelles
          utiles pour préserver la sécurité de vos données personnelles et
          empêcher toute intrusion ou accès non autorisé à ces données.
        </p>

        <p class="mb-4">
          Nous vous proposons un système de paiement en ligne sécurisé grâce aux
          dernières technologies en vigueur. Summit Maroc lutte également contre
          les pratiques d'usurpation d'identité sur internet en utilisant un
          dispositif de détection des paiements frauduleux.
        </p>

        <p class="mb-4">
          La sécurité et la confidentialité des données personnelles reposent
          également sur les bonnes pratiques de chacun. Nous vous invitons à ne
          pas communiquer vos mots de passe à des tiers et à vous déconnecter
          systématiquement de votre profil après usage.
        </p>

        <p class="mb-4">
          Enfin, Summit Maroc a mis en place une procédure de gestion de failles
          de sécurité permettant d’appréhender efficacement les éventuelles
          violations de données personnelles et de vous informer en cas de
          besoin.
        </p>

        <h3 class="text-lg font-bold mb-2">
          DONNÉES PERSONNELLES CONCERNANT LES MINEURS
        </h3>

        <p class="mb-4">
          Summit Maroc ne collecte ni ne traite de données personnelles
          relatives aux enfants de moins de 16 ans sans accord préalable des
          parents ou titulaires de la responsabilité parentale à l’égard de
          l’enfant.
        </p>

        <p class="mb-4">
          Si des données personnelles concernant des enfants sont collectées via
          les site internet ou sites mobiles de Summit Maroc, les parents ou
          titulaires de l’exercice de l’autorité parentale ont la possibilité de
          s’y opposer en nous contactant à l’adresse indiquée en bas de cette
          page.
        </p>

        <p class="mb-4">
          Par ailleurs, l’enfant mineur au moment de la collecte de ses données
          personnelles peut en obtenir l’effacement dans les meilleurs délais.
        </p>

        <h3 class="text-lg font-bold mb-2">MODIFICATIONS</h3>

        <p>
          Summit Maroc se réserve le droit d'adapter la politique de protection
          des données personnelles et s’engage à vous informer sur son site
          internet des changements ou compléments.
        </p>
      </div>
      <Footer />
    </div>
  );
};
