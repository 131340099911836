import { Footer } from '../components/Footer';
import { FixedBand } from '../components/fixed-band';

export const CGV = () => {
  window.scrollTo(0, 0);
  return (
    <div>
      <div className="h-auto flex justify-center items-center">
        <FixedBand withCalendly={true} />
      </div>
      <div class="container mt-36 mx-auto px-4 py-8">
        <h1 class="text-2xl font-bold mb-4">
          Contrat de Séjour - Summit Maroc
        </h1>

        <div class="mb-8">
          <h2 class="text-lg font-semibold mb-2">Cadre</h2>
          <p class="mb-4">
            Summit Maroc a pour vocation de proposer des stages de formation
            alliant des cours en création d’entreprise et de remises en forme.
          </p>
          <p class="mb-4">Nous ne sommes pas un centre médical ou une école.</p>
        </div>

        <div class="mb-8">
          <h2 class="text-lg font-semibold mb-2">Dispositions générales</h2>
          <p class="mb-4">
            La fiche d’inscription fait office de contrat de séjour. Elle
            établit les termes et conditions de séjour. Sur celle-ci doivent
            être inscrits les dates et la durée du séjour. Le participant ne
            pourra en aucune circonstance se prévaloir d'un quelconque droit au
            maintien dans les lieux à l'expiration de la période de location
            initialement prévue sur le présent contrat, sauf accord de la
            direction.
          </p>
          <p class="mb-4">
            Aucune modification (rature, surcharge, ...) ne sera acceptée dans
            la rédaction du contrat sans l'accord des deux parties.
          </p>
          <p class="mb-4">
            La direction s'engage à ne divulguer à aucun tiers les informations
            de quelque nature que ce soit, sur quelque support que ce soit, que
            le participant aura été amené à lui donner à l'occasion de
            l'exécution du présent contrat.
          </p>
          <p class="mb-4">
            Ces dernières dispositions ne sont toutefois pas applicables
            s'agissant des demandes de renseignements qui seraient formulées par
            les administrations et/ou les Tribunaux.
          </p>
        </div>
        <div class="mb-8">
          <h2 class="text-lg font-semibold mb-2">Paiement</h2>
          <p class="mb-4">
            La réservation devient effective dès lors que le participant aura
            fait parvenir à la direction un acompte d’au moins 50% du montant
            total du prix du séjour. Il appartient au participant de conserver
            une copie du document signé. Nos prix s'entendent hors charges
            comprises. Le solde est à régler avant l’arrivée au Maroc par Stripe
            ou virement.
          </p>
        </div>

        <div class="mb-8">
          <h2 class="text-lg font-semibold mb-2">
            Annulation par le participant
          </h2>
          <p class="mb-4">
            Toute annulation doit être notifiée par mai à
            younescoachingpro@gmail.com
          </p>
          <p class="mb-4">
            Annulation avant le début du séjour : si l'annulation intervient
            plus de 30 jours avant le début du séjour, le montant du séjour vous
            sera remboursé sur simple demande.
          </p>
          <p class="mb-4">
            Si l'annulation intervient entre 7 et 30 jours avant le début du
            séjour, le montant des arrhes reste acquis à Marco Summit.
          </p>
        </div>
        <div class="mb-8">
          <h2 class="text-lg font-semibold mb-2">Annulation par Summit</h2>
          <p class="mb-4">
            Lorsqu'avant le début du séjour, Summit annule ce séjour, il doit
            informer le client par lettre ou courrier électronique. Le client
            sera remboursé immédiatement des sommes versées.
          </p>
        </div>
      </div>

      <Footer />
    </div>
  );
};
