import React from 'react';
import { Collapse, Button, Card, CardBody } from '@material-tailwind/react';

export const CollapseDefault = (props) => {
  return (
    <div className="w-full">
      <Button
        onClick={() => props.setOpen(!props.isOpen)}
        className="text-sm rounded-lg my-3 font-normal bg-[#0F0F5C] md:text-3xl w-11/12 min-h-16"
      >
        <div className="flex items-center font-medium justify-center text-sm w-full  m-auto">
          {props.title}
        </div>
      </Button>
      <Collapse
        className="w-11/12 max-h-[200px] lg:max-h-[300px] collapse-card scrollable-element overflow-y-scroll"
        open={props.isOpen}
      >
        <Card className="bg-[#E8E8E8] scrollable-element border-[#E8E8E8] border-2 p-2">
          <CardBody className="scrollable-element p-1 lg:p-3">
            <div>
              {props.content.map((item, index) => {
                return (
                  <div key={index} className="mb-3">
                    {item}
                  </div>
                );
              })}
            </div>
          </CardBody>
        </Card>
      </Collapse>
    </div>
  );
};
