import { allInclusive } from './all-inclusive';
import check from '../img/check.svg';

export const AllInclusive = () => {
  return (
    <div className="relative bg-frame-mobile lg:bg-frame-desktop mt-14 pb-6 bg-cover lg:mb-24  lg:bg-no-repeat bg-center lg:h-[240px] lg:flex lg:w-full lg:items-center">
      <div className="absolute w-full h-full bg-black/15 lg:mt-[23px] z-0"></div>
      <div className="relative pt-6 pl-6 pr-3 lg:flex-col lg:justify-start lg:pt-0 lg:pl-24 lg:w-5/12 z-10">
        <h2 className="text-white text-3xl mb-10 lg:mb-3 font-bold lg:text-5xl ">
          All Inclusive
        </h2>
        <p className="text-md font-normal lg:font-semibold lg:text:3xl">
          Des billets d'avion aux 100 jours de transformation, en passant par
          l'hébergement, le sport et les repas, tout est inclus dans le prix de
          votre séjour.
        </p>
      </div>

      <div className="relative mt-8 lg:flex lg:flex-wrap lg:w-7/12 lg:mt-0 z-10">
        {allInclusive.map((item, index) => {
          return (
            <div
              key={index}
              className="flex items-center font-semibold justify-start lg:items-start ml-6 mt-5 lg:w-[300px]"
            >
              <img src={check} alt="" className="w-[20px] h-[20px] mr-3" />
              <span>{item}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};
