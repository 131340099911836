import group from '../img/groupe coaching village.jpg';
import { ButtonCalendly } from './button-calendly';
export const CoachingVillage = () => {
  return (
    <div className="ml-6 lg:flex lg:mt-44 lg:mb-34 lg:w-11/12 lg:mx-auto lg:mb-52">
      <div className="lg:w-[50%] lg:flex-col lg:items-start  lg:justify-start">
        <h2 className="my-10 text-3xl text-[#75A1FF] font-bold lg:text-6xl lg:mt-0 lg:mb-12">
          Coaching Village
        </h2>
        <div className="relative w-[100%] lg:hidden my-10">
          <div className="w-[100%] relative mt-10 mx-auto -translate-x-[10px]">
            <img className="relative rounded-[5px]" src={group} alt="dev" />
          </div>
        </div>

        <p className="mt-5 w-11/12 font-medium">
          Sous la direction bienveillante de Kami, nous serons accueillis dans
          un domaine unique qui est bien plus qu'un simple refuge pour une
          transformation physique. Avec un environnement propice à{' '}
          <strong className="text-[#75A1FF]">la santé et au bien-être</strong>,
          cet endroit représente une opportunité unique pour amorcer un{' '}
          <strong className="text-[#75A1FF]">
            changement significatif dans nos vies.
          </strong>
        </p>

        <p className="mt-5 w-11/12 font-medium">
          De plus, la diversité des participants crée une atmosphère riche en
          échanges et en opportunités de réseautage, offrant ainsi un cadre{' '}
          <strong className="text-[#75A1FF]">
            idéal pour établir des relations d'affaires fructueuses.
          </strong>
        </p>

        <p className="mt-5 w-11/12 font-medium">
          En somme, venir au Summit Maroc est bien plus qu'une expérience
          personnelle; c'est une invitation à se transformer et à tisser des
          liens précieux dans une communauté engagée vers{' '}
          <strong className="text-[#75A1FF]">
            l'épanouissement personnel et professionnel.
          </strong>
        </p>
        <div className="mt-14 mb-6 lg:float-start">
          <ButtonCalendly mobileCssTrick={true} />
        </div>
      </div>
      <div className="hidden lg:flex lg:items-center lg:w-[50%]">
        <img
          className="relative w-[100%] h-auto rounded-[5px]"
          src={group}
          alt="dev"
        />
      </div>
    </div>
  );
};
