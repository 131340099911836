import { SwiperReview } from './Swipers/SwiperReviews';

export const Reviews = () => {
  return (
    <div className="bg-[] lg:pb-12 lg:pt-0 lg:flex-col  lg:justify-center lg:items-center flex-wrap lg:flex">
      <div className="m-auto h-[200px] bg-[#F54C5A] lg:h-80 w-full items-center justify-center flex flex-col lg:w-12/12">
        <h2 className="text-3xl text-center font-bold lg:mt-14 lg:text-6xl">
          Avis clients
        </h2>
        <p className="text-center lg:mb-12">Ils parlent de nous.</p>
      </div>
      <div className="swiper-reviews-mobile lg:pt-12 lg:w-5/12">
        <div className="lg:w-12/12">
          <SwiperReview />
        </div>
      </div>
    </div>
  );
};
