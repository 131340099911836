import { Link } from 'react-router-dom';

export const Footer = () => {
  return (
    <footer className=" text-white bg-[#F54C5A]text-center h-[65.5vh]">
      <div className="flex flex-col justify-center h-full lg:flex-row lg:justify-around">
        <span className="block  text-center font-normal lg:flex items-center my-5 text-md">
          Copyright ©️ 2024. <br></br> All Rights Reserved. <br></br> Summit
        </span>
        <div className="mt-10 mb-10 lg:mb-0 flex justify-center text-white font-medium lg:mt-0 lg:flex lg:items-center">
          <a href="https://nektar.agency">
            Site réalisé par{' '}
            <span className="bg-[#F54C5A] text-black p-1 rounded-md">
              Nektar Agency
            </span>
          </a>
        </div>

        <div>
          <ul className="text-sm text-gray-40 flex flex-col lg:flex-row items-center 0 lg:flex lg:items-center lg:h-full">
            <li className="list-none lg:mr-2 mx-auto">
              <Link to="/mentions-legales">Mentions légales</Link>
            </li>
            {/*             <Link to="/conditions-generales-vente">
              <li className="list-none lg:mx-4">
                Conditions générales de ventes
              </li>
            </Link> */}

            <li className="list-none">
              <Link to="/politique-de-confidentialite">
                Politique de confidentialité
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};
