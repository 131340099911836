import '../App.css';
import video1 from '../video/video2.mp4';
import poster from '../img/poster.png';
import business from '../img/business.svg';
import tech from '../img/tech.svg';
import mindset from '../img/mindset.svg';
import sport from '../img/sport.svg';
import nutrition from '../img/nutrition.svg';

export const Video = () => {
  return (
    <div className="flex flex-wrap rounded-lg w-[95%]  mx-auto justify-center items-center mt-14 lg:mx-0 lg:w-[600px] ">
      <video
        id="video"
        className="rounded-xl"
        src={video1}
        width="800"
        height="400"
        controls={true}
        autoPlay={false}
        poster={poster}
        playsInline={true}
        muted={false}
      />
      <div className="w-11/12 flex flex-wrap items-center justify-between lg:flex-row">
        <div className="w-[40px] flex flex-col justify-center items-center mt-5">
          <img src={business} />
          <span className="text-sm font-medium block mt-5">Business</span>
        </div>

        <div className="w-[40px] flex flex-col justify-center items-center mt-5">
          <img src={tech} />
          <span className="text-sm font-medium block mt-5">Tech</span>
        </div>

        <div className="w-[40px] flex flex-col justify-center items-center mt-5">
          <img src={mindset} />
          <span className="text-sm font-medium block mt-5">Mindset</span>
        </div>

        <div className="w-[40px] flex flex-col justify-center items-center mt-5">
          <img src={sport} />
          <span className="text-sm font-medium block mt-5">Sport</span>
        </div>

        <div className="w-[40px] flex flex-col justify-center items-center mt-5">
          <img src={nutrition} />
          <span className="text-sm font-medium block mt-5">Nutrition</span>
        </div>
      </div>
    </div>
  );
};
