import riad from '../img/riad.jpeg';
import elliplse from '../img/Ellipse 7.png';
import group from '../img/Group 919.png';
import substract from '../img/Subtract.png';
import { ButtonCalendly } from './button-calendly';

export const SectionConcept = () => {
  return (
    <div className="relative mt-24 ml-6 lg:flex lg:flex-row-reverse lg:items-center lg:justify-center lg:mt-36 lg:mx-auto lg:w-11/12">
      <div className="lg:flex lg:flex-col  h-full lg:justify-between lg:pl-12 lg:w-6/12 lg:items-start">
        <h2 className="text-3xl text-[#75A1FF] font-bold lg:text-5xl">
          Un concept unique
        </h2>
        <p className="mt-5 pr-5 w-full lg:mx-0 lg:text-base">
          Nous avons conçu un programme et sélectionné l'endroit idéal pour vous
          offrir une transformation sans précédent. Un programme axé sur le{' '}
          <strong className="text-[#75A1FF]">
            business, la tech, le mindset, le sport et la nutrition.
          </strong>{' '}
          Sur un domaine de 4 hectares, nous privatisons un Riad de 10 chambres
          au Maroc.
        </p>
        <p className="mt-5 pr-5 w-full lg:mx-0 lg:text-base">
          Pendant 10 jours, vous serez immergé dans un environnement propice à{' '}
          <strong className="text-[#75A1FF]">la santé et au bien-être</strong>,
          pour amorcer un changement significatif dans votre vie. Des ateliers
          sur <strong className="text-[#75A1FF]">le business et la tech</strong>{' '}
          vous seront proposés pour vous aider à développer votre projet.
        </p>
        <p className="mt-5 pr-5 w-full lg:mx-0 lg:text-base">
          Enfin, <strong className="text-[#75A1FF]">pendant 90 jours</strong>,
          vous serez accompagné par des experts de nos réseaux pour un programme
          de coaching intensif.
        </p>
        <div className="hidden lg:flex mt-14 w-full justify-end float-end">
          <ButtonCalendly mobileCssTrick={true} />
        </div>
      </div>

      <div className="relative w-[95%] flex items-start justify-center my-20 lg:w-[50%]">
        <div className="lg:w-[600px] relative">
          <img className="rounded-xl -z-10" alt="" src={riad} />
          <img
            className="absolute w-[70px] -top-[15px] lg:-top-[35px] -left-[20px] lg:-left-[50px] -z-10 rounded-xl lg:w-[120px]"
            src={elliplse}
            alt=""
          />
          <img
            className="absolute top-[40px] -right-[15px] z-20 rounded-xl"
            src={substract}
            alt=""
          />
          <img
            className="absolute -bottom-[20px] -left-[10px] z-20 rounded-xl"
            src={group}
            alt=""
          />
        </div>
      </div>
      <div className="lg:hidden">
        <ButtonCalendly mobileCssTrick={true} />
      </div>
    </div>
  );
};
