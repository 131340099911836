import { Criteria } from './criteria';
import { criterias } from './criterias';
import tache from '../img/Ellipse 581.png';
import { ButtonCalendly } from './button-calendly';
import { SwiperForWho } from './Swipers/SwiperForWho';

export const ForWho = () => {
  return (
    <div className="mt-14 px-6 xl:flex lg:px-0 lg:mx-auto xl:mt-36 lg:justify-between xl:w-11/12">
      <div className="xl:flex-col xl:w-6/12">
        <h2 className="text-3xl xl:text-5xl translate-y-[40px] xl:pr-12 mb-20 text-[#75A1FF] mt-10 xl:mt-0 font-bold xl:mb-10 xl:translate-y-[0px]">
          Pour qui est fait ce programme ?
        </h2>
        <div className="relative w-[100%]  my-10">
          <div className="w-full max-w-[800px] relative mt-10 xl:hidden">
            <SwiperForWho />
          </div>
        </div>
        <div className="relative w-full  xl:mx-0 xl:pr-12">
          {criterias.map((criteria, index) => (
            <Criteria key={index} text={criteria} />
          ))}
        </div>
        <div className="hidden xl:flex mt-14 xl:w-12/12">
          <ButtonCalendly />
        </div>
      </div>
      <div className="hidden xl:flex xl:items-start xl:w-fit">
        <SwiperForWho />
      </div>
      <div className="xl:hidden mt-14">
        <ButtonCalendly />
      </div>
    </div>
  );
};
