import { CollapseDefault } from './CollapseDefault';
import { useState } from 'react';
import fun from '../img/fun.jpg';
import fun2 from '../img/fun2.jpg';
import { useEffect } from 'react';
import { collapse } from './collapse';

export const FAQ = () => {
  const [openBoxIndex, setOpenBoxIndex] = useState(null);

  useEffect(() => {
    const onScroll = () => {
      const faqElement = document.getElementById('faq');
      if (!faqElement) return; // Check if the element exists

      const faqTop = faqElement.offsetTop;
      const pic1 = document.getElementById('pic1');
      const pic2 = document.getElementById('pic2');

      if (window.scrollY > faqTop) {
        pic1.classList.add('transition-transform');
        pic2.classList.add('transition-transform-2');
      } else {
        pic1.classList.remove('transition-transform');
        pic2.classList.remove('transition-transform-2');
      }
    };

    onScroll();

    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  const handleBoxClick = (index) => {
    setOpenBoxIndex(index === openBoxIndex ? null : index);
  };

  return (
    <div id="faq" className="bg-white overflow-x-hidden  py-3 lg:flex lg:py-24">
      <div className="lg:w-5/12">
        <h2 className="text-3xl pl-6 text-[#040436] my-10 font-bold lg:mt-0 lg:text-6xl">
          FAQ
        </h2>
        <p className="w-[95%] pl-6 text-sm text-[#274265] mb-6 font-medium lg:text-xl">
          Vous avez des questions ? Nous avons des réponses. Si vous ne trouvez
          pas ce que vous cherchez, n'hésitez pas à nous contacter.
        </p>
        <div className="max-h-[500px] w-7/12 lg:w-full h-[400px] mx-auto lg:mt-24 flex items-center justify-center relative">
          <img
            id="pic1"
            alt=""
            className="faq-picture max-h-[400px] absolute z-20 border-2 border-[#131369] rounded-xl mx-auto"
            src={fun}
          />
          <img
            id="pic2"
            alt=""
            className="faq-picture max-h-[400px] absolute -translate-x-[50%] left-[50%] border-2  z-10 border-[#131369] rounded-xl mx-auto"
            src={fun2}
          />
        </div>
      </div>

      <div className="pl-6 lg:w-7/12 lg:max-w-[700px] flex flex-col items-center mx-a justify-center">
        {collapse.map((item, index) => {
          return (
            <CollapseDefault
              key={index}
              id={index.toString()}
              isOpen={index.toString() === openBoxIndex}
              setOpen={() => handleBoxClick(index.toString())}
              title={item.question}
              content={item.answer}
            />
          );
        })}
      </div>
    </div>
  );
};
