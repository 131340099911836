import '../App';
import { AllInclusive } from '../components/AllInclusive';
import { Application } from '../components/Application';
import { CoachingVillage } from '../components/CoachingVillage';
import { Essaouira } from '../components/Essaouira';
import { FAQ } from '../components/FAQ';
import { Footer } from '../components/Footer';
import { ForWho } from '../components/ForWho';
import { HeaderBase } from '../components/HeaderBase';
import { Intervenants } from '../components/Intervenants';
import { ProgramBootcamp } from '../components/ProgramBootcamp';
import { ProgramCoaching } from '../components/ProgramCoaching';
import { Reviews } from '../components/Reviews';
import { SectionConcept } from '../components/SectionConcept';
import { Tarif } from '../components/Tarif';
import { ButtonCalendly } from '../components/button-calendly';
import { FixedBand } from '../components/fixed-band';
import { GoToTop } from '../components/go-to-top';

export const Home = () => {
  window.scrollTo(0, 0);

  return (
    <div className="App">
      <FixedBand />

      <HeaderBase />
      <SectionConcept />
      <ForWho />
      <ProgramBootcamp />
      <ProgramCoaching />
      <Intervenants />
      <CoachingVillage />
      <AllInclusive />
      <Application />
      <Tarif />
      <Essaouira />
      <FAQ />
      <Reviews />
      <div className="w-full bg-[#fff] py-14">
        <ButtonCalendly withDiscord={true} />
      </div>
      <Footer />
      <GoToTop />
    </div>
  );
};
