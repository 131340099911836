import target from '../img/Frame 19352693.png';

export const Criteria = ({ text }) => {
  return (
    <div className="w-full mt-5 relative z-10s">
      <div className="flex justify-between items-start">
        <img src={target} className="z-20" alt="" />
        <p className="ml-2 w-full text-sm mx-auto font-semibold sm:text-lg md:text-base">
          {text}
        </p>
      </div>
    </div>
  );
};
