export const programmBootcamp = [
  'Sales',
  'Partenariat',
  'Marketing',
  'Personal Branding',
  'Réseaux sociaux',
  'Lancer des POCs',
  'Créer un MVP',
  'Pitch produit',
  'Outils tech',
  'RGPD',
  'Aides financières',
  'Scalabilité',
  'Sécurité',
  'Juridique',
  'Comptabilité',
  'Recrutement et associés',
  'Stratégies de pivot',
  'Mise en situation réelle',
  'Challenges',
  'Photo professionnelle',
];
