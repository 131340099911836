import { ButtonCalendly } from './button-calendly';

export const Application = () => {
  return (
    <div className="ml-6 mt-10 lg:mt-40">
      <div className="bg-black/20 px-4 py-8 w-[93%] border-2 border-[#7070DB] rounded-3xl ml-0 lg:flex lg:max-w-[1350px] lg:mx-auto">
        <div className="lg:max-w-[500px]">
          <h3 className="text-2xl text-white font-bold">
            Comment candidater ?
          </h3>
          <p className="w-full text-sm text-[#CAB7B7] mt-5 font-medium">
            Nous croyons fermement en l'importance de la cohésion, du dynamisme
            et des synergies au sein du groupe.
            <br />
            <br />
            Ainsi, lors du processus de sélection des candidatures, nous
            accorderons une attention particulière à ces aspects, en veillant à
            choisir les participants qui peuvent apporter une valeur ajoutée à
            l'ensemble.
          </p>
          <div className="hidden lg:flex mt-14 lg:float-start">
            <ButtonCalendly />
          </div>
        </div>
        <div className="mt-10 lg:mt-0 lg:w-full lg:flex lg:justify-center lg:items-start">
          <ul>
            <li className="text-sm ml-3 mt-3 lg:text-xl">
              Être disponible du 31 mai au 9 juin
            </li>
            <li className="text-sm ml-3 mt-3 lg:text-xl">
              Avoir un passport valide jusqu'au 9 juin
            </li>
            <li className="text-sm ml-3 mt-3 lg:text-xl">
              Prendre un rendez-vous Calendly
            </li>
          </ul>
        </div>
        <div className=" mt-10 lg:hidden">
          <ButtonCalendly />
        </div>
      </div>
    </div>
  );
};
