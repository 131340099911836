import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import seb from '../../img/sebastien.jpeg';
import younes from '../../img/younes.png';
import kami from '../../img/kami.jpeg';
import linkedin from '../../img/linkedin.svg';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

export const SwiperCoachs = () => {
  return (
    <div className="w-11/12">
      <Swiper
        style={{
          '--swiper-pagination-color': '#F54C5A',
          '--swiper-pagination-color-active': '#75A1FF',
          '--swiper-pagination-bullet-size': '15px',
          '--swiper-pagination-bullet-width': '15px',
          '--swiper-pagination-bullet-inactive-color':
            'rgba(255, 255, 255, 0.2)',
          '--swiper-pagination-bullet-inactive-opacity': '1',
          '--swiper-pagination-bullet-inactive-width': '50px',
        }}
        spaceBetween={10}
        pagination={{ clickable: true }}
        className="swiper-coach-mobile max-w-[450px] h-[410px] lg:max-w-[500px]"
        loop={true}
        modules={[Pagination, Autoplay, Navigation]}
      >
        <SwiperSlide className=" flex items-center">
          <img className="rounded-xl border-2 border-red-50" src={seb} alt="" />
        </SwiperSlide>
        <SwiperSlide className=" flex items-center">
          <div className="max-h-[358px]">
            <div className="scrollable-element max-h-[358px] border-2 border-red-50 bg-[#080845] text-lg pb-5 rounded-xl overflow-hidden overflow-y-scroll lg:px-3 lg:max-h-[600px] lg:pb-12 lg:py-12 lg:overflow-y-hidden lg:overflow-auto">
              <div className="text-2xl font-bold flex justify-between border-b-2 py-5 px-3  sticky top-0 bg-[#080845]">
                <h3>Sébastien Bianchi</h3>
                <a
                  target="_blank"
                  href="https://www.linkedin.com/in/bianchis1/"
                >
                  <img className="w-8" src={linkedin} />
                </a>
              </div>
              <p className="text-gray-500 text-sm  px-3 pt-3">
                Depuis plus de 20 ans Sébastien évolue de le monde des
                applications mobiles et d’internet. Il a été développeur,
                architecte, devops, CTO, chef de produit, Scrum master, project
                manager mais aussi entrepreneur.
              </p>
              <p className="text-gray-500 text-sm px-3 mt-3">
                {' '}
                Il a notamment travaillé avec des grands groupes tels que Canal
                +, Société Générale, Renault, Vinci, Orange, Deloitte, Louis
                Vuitton, mais aussi avec des startups telles que Klaro,
                Ekimetrics, Ordoclic, Okamedia, Stootie, INTELLIGENCE aNESTHESIA
                et même pour le gouvernement français (Ministère des Finances,
                services du premier Ministre, etc.).
              </p>
              <p className="text-gray-500 text-sm px-3 mt-3">
                Il partagera avec vous les enseignements tirés de ses réussites,
                mais aussi de ses échecs (qui sont peut-être encore plus
                interessants !).
              </p>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide className=" flex items-center">
          <img
            className="rounded-xl border-2 border-red-50"
            src={younes}
            alt=""
          />
        </SwiperSlide>
        <SwiperSlide className=" flex items-center">
          <div className="max-h-[358px]">
            <div className="scrollable-element max-h-[358px] border-2 border-red-50 bg-[#080845] text-lg pb-5 rounded-xl overflow-hidden overflow-y-scroll lg:px-3 lg:max-h-[600px] lg:pb-12 lg:py-12 lg:overflow-y-hidden lg:overflow-auto">
              <div className="text-2xl font-bold flex justify-between border-b-2 py-5 px-3  sticky top-0 bg-[#080845]">
                <h3>Younès Chaoui</h3>
                <a
                  target="_blank"
                  href="https://www.linkedin.com/in/youneschaoui/"
                >
                  <img className="w-8" src={linkedin} />
                </a>
              </div>
              <p className="text-gray-500 text-sm px-3 pt-3">
                Fort d'une expérience professionnelle de 15 ans, conjugue
                habilement commerce, marketing et ingénierie logicielle. Son
                parcours l'a conduit à travailler dans des secteurs variés,
                notamment dans l'industrie pétrolière et gazière, où il a
                collaboré avec des acteurs majeurs tels que Total, ENI et
                Saipem, tant en France qu'à l'étranger.
              </p>
              <p className="text-gray-500 text-sm px-3 mt-3">
                Doté d'une polyvalence remarquable, Younes a également exercé en
                tant que développeur pour des marques prestigieuses telles que
                Chanel, Hermès, Fortuneo et le Crédit Agricole. Sa capacité à se
                positionner efficacement sur le marché lui a permis de décrocher
                aisément des missions enrichissantes.
              </p>
              <p className="text-gray-500 text-sm px-3 mt-3">
                Au fil des années, Younes s'est distingué en tant que
                développeur, contribuant à la création de projets et
                d'applications web variés. Son expertise lui a valu de générer
                plus de 400 000 € de chiffre d'affaires, lui offrant ainsi la
                liberté de planifier son temps et de profiter de 3 mois de
                vacances par an.
              </p>
              <p className="text-gray-500 text-sm px-3 mt-3">
                Familier du Coaching Village, le lieu où se déroulera
                l'événement, Younes se présente comme le guide idéal sur place.
                Il assure la logistique et l'organisation avec brio, tout en
                apportant son soutien sur les aspects commerciaux et marketing,
                mettant ainsi ses compétences au service de la réussite
                collective.
              </p>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide className=" flex items-center">
          <img
            className="rounded-xl border-2 border-red-50"
            src={kami}
            alt=""
          />
        </SwiperSlide>
        <SwiperSlide className=" flex items-center">
          <div className="max-h-[358px]">
            <div className="scrollable-element max-h-[358px] border-2 border-red-50 bg-[#080845] text-lg pb-5 rounded-xl overflow-hidden overflow-y-scroll lg:px-3 lg:max-h-[600px] lg:pb-12 lg:py-12 lg:overflow-y-hidden lg:overflow-auto">
              <div className="text-2xl font-bold flex justify-between border-b-2 py-5 px-3  sticky top-0 bg-[#080845]">
                <h3>Kami Batcho</h3>
                <a
                  target="_blank"
                  href="https://www.linkedin.com/in/kami-batcho-createur-de-coaching-village/"
                >
                  <img className="w-8" src={linkedin} />
                </a>
              </div>
              <p className="text-gray-500 text-sm px-3 pt-3">
                Passionné par le sport et la nutrition, Kami s'est donné pour
                mission d'aider un maximum de gens à être bien dans son corps.
              </p>
              <p className="text-gray-500 text-sm px-3 mt-3">
                Pour cela, il a créer Coaching Village. Un centre de remise en
                forme pas comme les autres.
              </p>
              <p className="text-gray-500 text-sm px-3 mt-3">
                Pas question de subir des régimes sans saveurs et pas d'ambiance
                médicales. Dans son centre, on mange de bons plats et on fait du
                sport dans un cadre convivivial.
              </p>
              <p className="text-gray-500 text-sm px-3 mt-3">
                Résultats : Il a fait perdre plus de 6000 kilos à l'ensemble de
                ses participants depuis 2016.
              </p>{' '}
              <p className="text-gray-500 text-sm px-3 mt-3">
                Profitez donc de votre séjour pour vous remettre en forme et
                repartir reboosté et des objectifs plein la tête.
              </p>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};
