import discord from '../img/discord.svg';
export const ButtonCalendly = ({
  mobileCssTrick = false,
  withDiscord = false,
}) => {
  const cssTrick = mobileCssTrick
    ? 'w-[calc(100%+5px)] -translate-x-[10px]'
    : '';
  return (
    <div className="flex justify-center w-full flex-col items-center">
      <a
        href="https://calendly.com/summit-maroc/summit"
        className={`bg-[#F54C5A] w-full ${cssTrick} max-w-[300px] lg:font-bold lg:text- lg:py-8 h-[58px] justify-center flex items-center font-medium  rounded-full text-white  py-2 px-4`}
      >
        Je summit ma candidature
      </a>
      {withDiscord && (
        <div className=" mt-5 flex items-center justify-center">
          <a
            href="https://discord.gg/JGqRB74G"
            className="text-[#5462B4] font-medium"
          >
            Rejoindre le discord
          </a>
          <img
            className="ml-2 -translate-y-[2px] w-[30px] h-[30px]"
            src={discord}
          />
        </div>
      )}
    </div>
  );
};
