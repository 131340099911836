import React from 'react';
import { FixedBand } from '../components/fixed-band';
import { Footer } from '../components/Footer';

function MentionsLegales() {
  window.scrollTo(0, 0);

  return (
    <div className="block">
      <div className="h-auto flex justify-center items-center">
        <FixedBand withCalendly={true} />
      </div>

      <div class="container mt-36 mx-auto px-4 py-8">
        <h1 class="text-2xl font-bold mb-4">Mentions Légales</h1>

        <div class=" rounded-lg p-6">
          <h2 class="text-xl font-semibold mb-4">Informations générales</h2>
          <p>
            Ce site est édité par Younès Chaoui, SAS en cours de création. Le
            siège social est situé au 126 Marie Curie, 59118 Wambrechies.
          </p>
          <p>Directeur de la publication : Younès Chaoui</p>
          <p>Contact : younescoachingpro@gmail.com</p>
        </div>

        <div class=" rounded-lg p-6 mt-4">
          <h2 class="text-xl font-semibold mb-4">Hébergement</h2>
          <p>
            Ce site est hébergé par la société Vercel Inc., située 340 S Lemon
            Ave #4133 Walnut, CA 91789, et joignable au (559) 288-7060.
          </p>
        </div>

        <div class=" rounded-lg p-6 mt-4">
          <h2 class="text-xl font-semibold mb-4">
            Protection des données personnelles
          </h2>
          <p>
            Les données personnelles collectées sur ce site sont destinées à
            Summit.
          </p>
          <p>
            Conformément à la loi "Informatique et Libertés" du 6 janvier 1978,
            vous disposez d'un droit d'accès, de rectification et de suppression
            des données vous concernant. Pour exercer ce droit, veuillez nous
            contacter à l'adresse suivante : younescoachingpro@gmail.com
          </p>
        </div>

        <div class=" rounded-lg p-6 mt-4">
          <h2 class="text-xl font-semibold mb-4">Propriété intellectuelle</h2>
          <p>
            L'ensemble de ce site relève de la législation française et
            internationale sur le droit d'auteur et la propriété intellectuelle.
            Tous les droits de reproduction sont réservés.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default MentionsLegales;
