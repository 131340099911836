import { ButtonCalendly } from './button-calendly';
import check from '../img/Vector (1).svg';
export const Tarif = () => {
  return (
    <div className="mt-9 lg:mt-14 bg-white pt-5 lg:py-12 pb-20 lg:flex lg:w-12/12 lg:mt-40">
      <div className="lg:w-6/12 pl-8 lg:pl-24">
        <h2 className="text-3xl text-[#000] my-10 font-bold lg:mt-0 lg:text-6xl">
          Tarif
        </h2>
        <p className="w-[95%]  text-sm lg:text-lg text-gray-600 mb-14 font-medium">
          Le tarif de participation couvre l'ensemble des prestations offertes
          lors de cet événement exclusif. Vous n'aurez pas à vous soucier des
          détails logistiques ni des frais additionnels, car tout est pris en
          charge. Considérez cette opportunité comme un investissement judicieux
          dans votre développement professionnel et personnel. Les connaissances
          acquises, les compétences renforcées et le réseau élargi que vous
          obtiendrez ici constitueront un socle solide pour votre réussite
          future. En effet, cet investissement se révélera rapidement rentable,
          ouvrant la voie à des opportunités lucratives et à une croissance
          professionnelle épanouissante.
        </p>
      </div>
      <div className="bg-black/60 px-4 py-8 w-[90%] mx-auto rounded-3xl  flex flex-col items-center lg:ml-auto lg:mr-24 justify-end lg:max-w-[500px]">
        <span className="bg-[#75A1FF]/45 px-6 py-3 w-6/12 text-center rounded-full mb-5 font-medium line-through text-lg">
          6 900 € TTC
        </span>
        <span className="bg-[#75A1FF] px-6 py-3 w-6/12 text-center rounded-full font-medium text-lg">
          5 900 € TTC
        </span>
        <i className="mt-1 text-sm">Facilité de paiement possible.</i>
        <ul className="mt-10 mx-auto flex flex-col items-start justify-start w-fit pl-2 text-lg">
          <li className="list-none mb-3 flex items-center">
            <img className="mr-2" src={check} />
            <span>Billet et réservation d'avion</span>
          </li>
          <li className="list-none mb-3 flex items-center">
            <img className="mr-2" src={check} />
            <span>All Inclusive</span>
          </li>
          <li className="list-none mb-3 flex items-center">
            <img className="mr-2" src={check} />
            <span>10+ intervenants</span>
          </li>
          <li className="list-none mb-3 flex items-center">
            <img className="mr-2" src={check} />
            <span>10 jours de bootcamp</span>
          </li>
          <li className="list-none mb-3 flex items-center">
            <img className="mr-2" src={check} />
            <span>90 jours de coaching</span>
          </li>
          <li className="list-none flex items-center">
            <img className="mr-2" src={check} />
            <span>100 jours de transformation</span>
          </li>
        </ul>
        <div className="float-start w-full mt-10">
          <ButtonCalendly />
        </div>
      </div>
    </div>
  );
};
