import { programmBootcamp } from './program-bootcamp';
import check from '../img/Vector.svg';
import work from '../img/PU NDC 18.png';
import group from '../img/group.jpg';
import { ButtonCalendly } from './button-calendly';
import React, { useEffect } from 'react';
import { useRef } from 'react';
import morning from '../img/morning.png';
import afternoon from '../img/daytime.png';
import night from '../img/night.png';

export const ProgramBootcamp = () => {
  const titleRef = useRef(null);

  useEffect(() => {
    const onScroll = () => {
      const bootElement = document.getElementById('bootcamp-container');
      if (!bootElement) return; // Check if the element exists

      const bootElementTop = bootElement.offsetTop;
      const flipContainer = document.getElementById('flip-container');

      if (window.scrollY > bootElementTop) {
        flipContainer.classList.add('flipped');
      } else {
        flipContainer.classList.remove('flipped');
      }
    };

    onScroll();

    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  return (
    <div
      id="bootcamp-container"
      className="ml-6 lg:max-w-[1700px]  lg:flex-col lg:mt-28 lg:mx-auto lg:w-11/12"
    >
      <h2
        ref={titleRef}
        className="my-10 text-3xl text-[#75A1FF] font-bold lg:text-6xl lg:w-[650px] lg:text-right lg:mb-24 lg:float-end"
      >
        10 jours de Bootcamp au Maroc
      </h2>

      <div className="bg-white px-4 py-8 w-[95%] lg:w-[100%] rounded-3xl ml-0 lg:mt-14 lg:flex">
        <div className="w-full lg:w-fit">
          <h2 className="text-black text-2xl mb-10 font-bold lg:text-4xl lg:mb-8">
            Du 31 mai au 9 juin
          </h2>
          <div className="mt-5 relative flex">
            <div id="flip-container" class="flip-card">
              <div class="flip-card-inner">
                <div class="flip-card-front">
                  <img src={group} alt="Avatar" />
                </div>
                <div class="flip-card-back">
                  <img src={work} alt="Avatar" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex-col lg:flex lg:flex-row lg:flex-wrap lg:w-full lg:ml-6 lg:h-auto lg:items-center lg:justify-start">
          {programmBootcamp.map((program, index) => {
            return (
              <div
                key={index}
                className="w-fit flex justify-start items-center lg:w-12/12 h-fit lg:w-[250px] ml-4 mt-5 lg:mt-0 relative z-10"
              >
                <img src={check} alt="" className="w-[20px] h-[20px]" />
                <div className="flex justify-between items-start">
                  <p className="ml-2 w-full text-[#0F0F5C] text-sm mx-auto font-semibold whitespace-nowrap lg:text-[16px]">
                    {program}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <h2 className="text-3xl  my-10 font-bold  lg:text-5xl lg:mb-16 lg:max-w-[555px] lg:text-right lg:float-end lg:my-24">
        Une journée type au Bootcamp
      </h2>
      <div className="lg:flex lg:w-full lg:justify-between lg:items-center">
        <div className="bg-white/20 px-4 py-8 w-[95%] rounded-3xl ml-0 xl:max-w-[400px] lg:h-[300px]">
          <div className="w-full flex justify-start items-center">
            <img src={morning} alt="" className="w-[50px] h-[50px] ml-5" />
            <h3 className="ml-3 text-2xl text-white font-bold">Matin</h3>
          </div>
          <div>
            <ul className="text-white pl-3 pt-6">
              <li className="text-[#75A1FF] list-none text-xl mb-5">
                <span className="text-[#75A1FF] font-bold">8h00 - 9h00</span>
                <span className="text-white ml-5">Petit déjeuner</span>
              </li>

              <li className="text-[#75A1FF] list-none text-xl mb-5">
                <span className="text-[#75A1FF] font-bold">9h30 - 10h30</span>
                <span className="text-white ml-5">Atelier</span>
              </li>
              <li className="text-[#75A1FF] list-none text-xl mb-5">
                <span className="text-[#75A1FF] font-bold">10h30 - 13h00</span>
                <span className="text-white ml-5">Pratique</span>
              </li>
              <li className="text-[#75A1FF] list-none text-xl mb-5">
                <span className="text-[#75A1FF] font-bold">11h00 - 12h00</span>
                <span className="text-white ml-5">
                  Sport <i className="text-sm"> (optionnel) </i>
                </span>
              </li>
            </ul>
          </div>
        </div>

        <div className="bg-white/20 px-4 py-8 w-[95%] my-10 lg:my-0 rounded-3xl ml-0 xl:max-w-[400px] lg:h-[300px]">
          <div className="w-full flex justify-start items-center">
            <img src={afternoon} alt="" className="w-[50px] h-[50px] ml-5" />
            <h3 className="ml-3 text-2xl text-white font-bold">Après-midi</h3>
          </div>
          <div>
            <ul className="text-white pl-3 pt-6">
              <li className="text-[#75A1FF] list-none text-xl mb-5">
                <span className="text-[#75A1FF] font-bold">13h00 - 14h00</span>
                <span className="text-white ml-5">Déjeuner</span>
              </li>

              <li className="text-[#75A1FF] list-none text-xl mb-5">
                <span className="text-[#75A1FF] font-bold">15h00 - 15h45</span>
                <span className="text-white ml-5">Atelier</span>
              </li>
              <li className="text-[#75A1FF] list-none text-xl mb-5">
                <span className="text-[#75A1FF] font-bold">16h00 - 20h00</span>
                <span className="text-white ml-5">Pratique</span>
              </li>
              <li className="text-[#75A1FF] list-none text-xl mb-5">
                <span className="text-[#75A1FF] font-bold">18h30 - 19h30</span>
                <span className="text-white ml-5">
                  Sport <i className="text-sm"> (optionnel) </i>
                </span>
              </li>
            </ul>
          </div>
        </div>

        <div className="bg-white/20 px-4 py-8 w-[95%] rounded-3xl ml-0 xl:max-w-[400px] lg:h-[300px]">
          <div className="w-full flex justify-start items-center">
            <img src={night} alt="" className="w-[50px] h-[50px] ml-5" />
            <h3 className="ml-3 text-2xl text-white font-bold">Soir</h3>
          </div>
          <div>
            <ul className="text-white pl-3 pt-6">
              <li className="text-[#75A1FF] list-none text-xl mb-5">
                <span className="text-[#75A1FF] font-bold">20h00 - 21h00</span>
                <span className="text-white ml-5">Diner</span>
              </li>
              <li className="text-[#75A1FF] list-none text-xl mb-5">
                <span className="text-[#75A1FF] font-bold">21h00 - 23h00</span>
                <span className="text-white ml-5">Jeu</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="mt-24">
        <ButtonCalendly mobileCssTrick={true} />
      </div>
    </div>
  );
};
