import { ButtonCalendly } from './button-calendly';
import long from '../img/long-term-planning.svg';
import leadership from '../img/leadership.svg';
import sprint from '../img/sprint.svg';
import live from '../img/live.svg';

export const ProgramCoaching = () => {
  return (
    <div className="ml-6 lg:w-11/12 lg:mx-auto">
      <h2 className="my-10 text-3xl text-[#75A1FF] font-bold lg:text-6xl lg:mt-36 ">
        90 jours de coaching <br /> à distance
      </h2>

      <p className=" pr-5 lg:text-lg my-6">
        Après avoir terminé notre bootcamp intensif, nous sommes déterminés à
        vous accompagner dans votre parcours professionnel avec{' '}
        <strong className="text-[#75A1FF]">
          notre programme de coaching de 90 jours.
        </strong>
      </p>
      <p className=" pr-5 lg:text-lg my-6">
        Conçu pour consolider vos acquis et vous aider à atteindre vos objectifs
        , ce programme offre{' '}
        <strong className="text-[#75A1FF]">
          une approche personnalisée et structurée.
        </strong>{' '}
        Au cours de ces trois mois, vous bénéficierez de{' '}
        <strong className="text-[#75A1FF]">
          six séances de coaching individuel
        </strong>
        , où vous aurez l'occasion de travailler en profondeur sur vos défis
        spécifiques et de recevoir des conseils personnalisés de nos experts. De
        plus, vous participerez à{' '}
        <strong className="text-[#75A1FF]">sept sprints de 15 jours,</strong>{' '}
        vous permettant de mettre en pratique les enseignements du bootcamp et
        d'atteindre des étapes clés dans votre parcours professionnel.
      </p>
      <p className=" pr-5 lg:text-lg my-6">
        Enfin, vous aurez accès à{' '}
        <strong className="text-[#75A1FF]">
          des sessions en direct avec des experts,
        </strong>{' '}
        abordant des sujets pertinents et répondant à vos questions spécifiques.
        Ce programme d'accompagnement vise à vous soutenir de manière holistique
        dans votre développement professionnel , en vous fournissant les outils,
        les conseils et le soutien nécessaires pour réussir.
      </p>

      <div className="lg:flex lg:flex-wrap lg:justify-between lg:items-center  lg:mx-auto">
        <h2 className="w-full text-2xl mb-10 font-bold lg:text-4xl lg:mb-8">
          Du 14 juin au 7 septembre
        </h2>
        <div className="bg-black/20 px-4 py-8 w-[93%] border-2 border-[#7070DB] rounded-3xl ml-0 lg:w-[47%]  lg:mr-3 lg:h-[270px]">
          <div className="bg-gradient-to-r from-purple-600 to-indigo-600 w-fit p-2 rounded-xl mb-5">
            <img className="w-[50px] h-[50px]" src={long} />
          </div>
          <h3 className="text-2xl text-[#75A1FF] lg:text-3xl uppercase font-bold">
            3 mois d'accompagnement
          </h3>
          <p className="w-full text-sm mt-5 font-semibold lg:text-xl">
            Une série d'itérations pour vous aider à atteindre vos objectifs
          </p>
        </div>

        <div className="bg-black/20 px-4 py-8 my-5 w-[93%] border-2 border-[#7070DB] rounded-3xl ml-0 lg:w-[47%]  lg:mr-3 lg:h-[270px]">
          <div className="bg-gradient-to-br from-orange-500 via-transparent to-blue-900 w-fit p-2 rounded-xl mb-5">
            <img className="w-[50px] h-[50px]" src={leadership} />
          </div>
          <h3 className="text-2xl text-[#75A1FF] lg:text-3xl uppercase font-bold">
            6 séances de coaching
          </h3>
          <p className="w-full text-sm mt-5 font-semibold lg:text-xl">
            Pour faire le point et préparer les prochaines étapes
          </p>
        </div>

        <div className="bg-black/20 px-4 py-8 my-5 w-[93%] border-2 border-[#7070DB] rounded-3xl ml-0 lg:w-[47%]  lg:mr-3 lg:h-[270px]">
          <div className="bg-gradient-to-br from-blue-800 via-transparent to-blue-900 w-fit p-2 rounded-xl mb-5">
            <img className="w-[50px] h-[50px]" src={sprint} />
          </div>
          <h3 className="text-2xl text-[#75A1FF] lg:text-3xl uppercase font-bold">
            7 sprints de 15 jours
          </h3>
          <p className="w-full text-sm mt-5 font-semibold lg:text-xl">
            Pour passer à l'action et avancer sur vos projets
          </p>
        </div>

        <div className="bg-black/20 px-4 py-8 w-[93%] border-2 border-[#7070DB] rounded-3xl ml-0 lg:w-[47%]  lg:mr-3 lg:h-[270px]">
          <div className="bg-gradient-to-br from-yellow-700 via-transparent to-orange-800 w-fit p-2 rounded-xl mb-5">
            <img className="w-[50px] h-[50px]" src={live} />
          </div>
          <h3 className="text-2xl font-bold text-[#75A1FF] lg:text-3xl uppercase">
            Des lives d'experts
          </h3>
          <p className="w-full text-sm mt-5 font-semibold lg:text-xl">
            Pour continuer d'apprendre et de progresser
          </p>
        </div>
      </div>
      <div className="lg:mt-24 mt-12 w-full flex  -mx-[15px]">
        <ButtonCalendly />
      </div>
    </div>
  );
};
