import { SwiperCoachs } from './Swipers/SwiperCoachs';
import { SwiperCoachsDesktop } from './Swipers/SwiperCoachsDesktop';

export const Intervenants = () => {
  return (
    <div className="mt-14 lg:w-11/12 lg:mx-auto">
      <div className="lg:flex lg:justify-end">
        <h2 className="text-3xl mb-6 ml-6 text-[#75A1FF] font-bold lg:text-6xl lg:my-12">
          Intervenants
        </h2>
      </div>

      <div className="">
        <div className="flex flex-col items-center xl:hidden">
          <SwiperCoachs />
        </div>
        <div className="hidden swiper-coachs-desktop w-full flex-col items-center xl:flex">
          <SwiperCoachsDesktop />
        </div>
      </div>
    </div>
  );
};
