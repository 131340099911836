export const allInclusive = [
  "Billet et réservation d'avion",
  'Transfert aéroport - domaine',
  'Hébergement',
  'Bilan corporel & diétéique',
  '4 repas par jour',
  'Ateliers',
  'Activités sportives',
  'Accompagnement',
  "Lives d'experts",
];
