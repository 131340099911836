import './App.css';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { Home } from './pages/Home';
import MentionsLegales from './pages/Mentions-Legales';
import { Confidentialite } from './pages/Confidentialite';
import { CGV } from './pages/CGV';

const router = createBrowserRouter([
  { path: '/', element: <Home /> },
  { path: '/mentions-legales', element: <MentionsLegales /> },
  { path: '/politique-de-confidentialite', element: <Confidentialite /> },
  { path: '/conditions-generales-vente', element: <CGV /> },
]);

function App() {
  return <RouterProvider router={router}></RouterProvider>;
}

export default App;
